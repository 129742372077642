.title{
  color: #0087b7;
  font-weight: 300;
  padding: 1.5rem;
  font-size: 3rem;
  margin: 0;
}

.center {
  text-align: center;
}

.timePeriodButton {
  position: absolute;
  right: 25%;
  width: 50%;
}

.timePeriodContainer {
  position: relative;
}
