.pop{
  padding: 1rem;
  background-color: #0087b7;
  color: white;
  text-align: center;
  list-style-type: none;
}

.tr{
  text-align: left;
}

:hover.iSyncp{
  color: #00bfb6;
  cursor: pointer;
}

.deviceTableContainer {
  width: 100%;
  padding: 5px;
}

.iSyncp{
  color: #0087b7;
}

:hover.iSyncp{
  color: #00bfb6;
  cursor: pointer;
}
