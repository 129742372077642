.iEdit{
  color: #0087b7;
}

:hover.iEdit{
  color: #00bfb6;
  cursor: pointer;
}

.formitems {
  margin-bottom: 100px;
}
