.inputField{
  padding: 10px;
}

.modT{
  font-weight: 500;
  font-size: 1.25rem;
}

.titi{
  font-weight: 500;
  color: #00bfb6;
  font-size: 2rem;
}

.txtFichier{
  font-weight: 300;
  font-size: 1.10rem;
  padding: 1rem;
}

.msg{
  color: #00bfb6;
}

.iPlus{
  color: #0087b7;
  margin: 5px;
}

:hover.iPlus{
  color: #00bfb6;
  cursor: pointer;
}

.iSyncm{
  color: #0087b7;
  margin: 5px;
}

:hover.iSyncm{
  color: #00bfb6;
  cursor: pointer;
}
