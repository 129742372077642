.blackLink {
  color: black;
}

.blackLink:visited {
  color: black;
}

.blackLink:hover {
  color: #0087b7;
}
