.whiteIcon:hover {
  color: black;
}

.blackIcon:hover {
  color: white;
}

.blackAckIcon:hover {
  color: #00bfb6;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.whiteCheckbox {
  color: pink[800]
}
