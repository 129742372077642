.name{
  padding-left: 1rem;
  color: #000000;
  font-weight: 800;
}

.item {
  padding: 5px;
  text-align: center;
}

.trash {
  width: 0.8em;
  height: 0.8em;
}
