.msg{
  margin-top: 20px;
  color: #00bfb6;
  text-align: center;
}

.formitems {
  margin-bottom: 10px;
}

.pictures {
  width: 50px;
}

.deleteIcons {
  color: #0087b7;
}

.deleteIcons:hover {
  color: #00bfb6;
}
