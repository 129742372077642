.deviceName{
  text-align: center;
  font-size: 1rem;
  background-color: #0087b7;
  padding: 0.5rem;
  border-radius: 3rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

:hover.deviceName{
  color: #0087b7;
  background-color: #00bfb6;
}
