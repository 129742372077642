.blackLink {
  color: white;
}

.blackLink:visited {
  color: white;
}

.blackLink:hover {
  color: #00bfb6;
}

.whiteLink, .whiteLink:visited {
  color: black;
}

.whiteLink:hover {
  color: #00bfb6;
}

.whiteIcon {
  color: back;
}

.whiteIcon:hover {
  color: #00bfb6;
}
