.name{
  padding-left: 1rem;
  color: #000000;
  font-weight: 800;
}

.center {
  margin: auto;
  width: 100%;
}

.Button-module_button__6y6Zv {
  width: 100%;
}

.item {
  padding: 5px;
  text-align: center;
}

.whiteLink {
  color: black;
}

.whiteLink:visited {
  color: black;
}

.trash {
  width: 0.8em;
  height: 0.8em;
}

.trash:hover {
  color: #00bfb6
}

.buttonContainer {
  margin: auto;
  width: 80%;
  padding: 10px;
}
.button{
  color : #00bfb6;
}
.rotate {
  transform: rotate(180deg);
}
