#titre{
  color: #0087b7;
  font-size: 3rem;
  font-weight: 300;
}

#textt{
  color:#0087b7;
}

.center {
  margin: auto;
  width: 100%;
}

.loginGridItem {
  width: 80%;
  max-width: 500px;
  padding: 0;
  margin: auto;
}

.inputContainer {
  width: 100%;
}

.item {
  padding-bottom: 50px;
}

.loginContainer {
  margin-top: 100px;
}

.loginItem {
  padding-bottom: 100px;
}

.formItem {
  width: 80%;
  padding-bottom: 15px;
}
