#head{
  color: #0087b7;
  font-weight: 300;
  font-size: 3rem;
  padding: 0;
}

.back{
  color: #0087b7;
  font-weight: 400;
  font-size: 2rem;
}

:hover.back{
  color: #00bfb6;
}

#fullScreenIcon {
  position: absolute;
  transform: scale(1.8);
  bottom: 10px;
  left: 10px;
  z-index: 9;
  color: white;
}
